@import '~include-media/dist/_include-media.scss';

$breakpoints: (
  phone: 421px,
  tablet: 769px,
  desktop: 1024px,
);

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  span {
    margin-top: 1rem;
  }
}
