@import url('https://rsms.me/inter/inter.css');

:root {
  --ff-black: #000000;
  --ff-smoke: #070707;
  --ff-charcoal: #101010;
  --ff-gray-1: #1c1c1e;
  --ff-gray-2: #2c2c2e;
  --ff-gray-3: #333333;
  --ff-gray-4: #3a3a3c;
  --ff-gray-5: #48484a;
  --ff-gray-6: #636366;
  --ff-gray-7: #8e8e93;
  --ff-snow: #fafafa;
  --ff-white: #ffffff;

  --ff-transparent: rgba(0, 0, 0, 0.1);
  --ff-bg-modal: #1a202c9a;
  --ff-blue-1: #003cff;
  --ff-blue-2: #3182ce;

  --ff-green: #38a169;
  --ff-green-dark: #22543d;
  --ff-green-trans: #38a16960;
  --ff-yellow: #d69e2e;
  --ff-yellow-dark: #744210;
  --ff-yellow-trans: #d69e2d60;
  --ff-red: #e53e3e;
  --ff-red-dark: #742a2a;
  --ff-red-trans: #e53e3e60;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'inter', sans-serif;
}

body {
  margin: 0;
  height: 100%;
  color: var(--ff-black);
  background-color: var(--ff-white);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  padding: 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid var(--ff-gray-7);
  border-radius: 0.5rem;
}

li {
  list-style: none;
}

::placeholder {
  color: var(--ff-gray-7);
}

#root {
  height: 100%;
}

/* UTILS */

.container {
  margin: auto;
  max-width: 1000px;
  height: 100%;
}

.bold {
  font-weight: 600;
}

.card {
  background-color: var(--ff-white);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.err-text {
  color: var(--ff-red);
}

/* MIXINS */

@mixin new-page-dimensions() {
  padding: 1rem;
  height: 90%;
  width: 100%;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
