@import 'components';

.modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 54, 54, 0.5);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  padding: 0.5rem;
  display: flex;
  flex-direction: column-reverse;

  & > div {
    border-radius: 1rem;
    padding: 1rem;
    height: 325px;
    color: var(--ff-black);
    background-color: var(--ff-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: var(--ff-gray-6);
  }

  form {
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.modalLg {
  @extend .modal;

  & > div {
    height: 500px;
  }
}

.footer {
  & > button {
    margin-top: 0.5rem;
  }
}

.input {
  margin-bottom: 1rem;
}

.plans {
  display: flex;
  height: 100%;
  padding: 1rem;
  max-height: calc(117px + 1rem);

  & > :first-child {
    margin-right: 1rem;
  }
}

.plan {
  height: 100%;
  min-height: 100px;
  max-height: 117px;
  width: 100%;
  border: var(--ff-gray-7) 2px solid;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: #20ff5b1e;
  border: 2px solid var(--ff-green) !important;
  color: var(--ff-green);

  p {
    color: var(--ff-green);
    font-weight: 500;
  }
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    background: none;
    border: none;
    color: var(--ff-blue-1);
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;

  p {
    font-weight: 500;
    margin-top: 2rem;
  }
}

.grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr;

  & > :last-child {
    margin-bottom: 2rem;
  }
}

@include media('≥phone') {
  .modal {
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
      max-width: 400px;
    }

    .button {
      max-width: 400px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
  }
}
