@import 'components';

.background {
  background-color: var(--ff-snow);
  color: var(--ff-black);
  height: 90%;
}

.dashboard {
  padding: 1rem 2rem;
  overflow-y: auto;
}

.header {
  h2 {
    margin-bottom: 1rem;
  }

  margin-bottom: 1rem;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 148px);

  svg {
    height: 180px;
    width: 200px;
  }

  p {
    text-align: center;
    color: var(--ff-gray-6);
    margin-bottom: 1rem;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  margin-bottom: 4rem;
}

.reqs {
  li {
    display: grid;
    grid-template-columns: 7% 93%;
  }
}

@include media('≥phone') {
  .dashboard {
    padding: 1rem;
  }

  .header {
    h2 {
      font-size: 2rem;
    }

    display: flex;
    justify-content: space-between;
  }

  .list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

// TODO: Make a better version of the single device view

@include media('>=tablet') {
  .singleDevice {
    & > div {
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 300px;
      width: 100%;
    }

    svg {
      height: 100%;
      width: 80%;
    }

    h4 {
      cursor: pointer;
      margin-top: 1rem;
      font-size: 2rem;
    }
  }

  .hero {
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
  }
}
